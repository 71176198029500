.vjs-poster {
  background-size: cover !important;

  @media screen and (max-width: 899px) {
    opacity: 0.59 !important;
  }
}

.vjs-title-bar,
.vjs-text-track-display,
.vjs-loading-spinner,
.vjs-big-play-button,
.vjs-control-bar,
.vjs-modal-dialog {
  display: none !important;
}
