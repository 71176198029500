@import 'shared/mixins';
@import 'shared/breakpoints';
@import 'shared/vw';
@import 'shared/colors';

.ewa-button {
  @include font-ttfirsneue();
  font-size: getVwMobile(14px);
  font-weight: 400;
  height: getVwMobile(42px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: $money-black;
  border: none;
  color: $white;

  &:hover {
    background: #000;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  @media (min-width: $lg) {
    font-size: getVwDesktop(16px);
    height: getVwDesktop(56px);
  }

  @media (min-width: $desktop) {
    font-size: 16px;
    height: 56px;
  }
}

.ewa-button.--outlined {
  background: none;
  border: 1px solid $money-black;
  color: $money-black;
}
