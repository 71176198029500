@import '../shared/mixins';
@import '../shared/breakpoints';
@import '../shared/constants';
@import '../shared/vw';

$diagram-minimizer: 1800;

.business-diagram {
  display: block;
  background: black;
  color: #a3a3a3;
  padding: getVwLaptop(18px) getVwLaptop(43px) getVwLaptop(36px) getVwLaptop(43px);
  @include font-montserrat;

  .gi {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
    font-size: getVw(31px, $diagram-minimizer);
    font-weight: 700;
    border-radius: getVw(33px, $diagram-minimizer);
    background: transparent;
    border: getVw(6px, $diagram-minimizer) solid #ff33b5;
    height: getVw(119.5px, $diagram-minimizer);
    padding: 0 getVw(40px, $diagram-minimizer);
    @include font-din-condensed;

    &--desktop {
      &-main {
        background: transparent;
        color: #ff33b5;
        border-color: #ff33b5;
      }

      &-secondary {
        background: transparent;
        color: white;
        border-color: white;
      }

      &-main-flat {
        background: #ff33b5;
        color: black;
        border-color: #ff33b5;
      }

      &-secondary-flat {
        background: white;
        color: black;
        border-color: white;
      }
    }
  }

  .accent {
    font-weight: 300;
    @include font-montserrat;

    &--main {
      color: #ff33b5;
    }

    &--white {
      color: white;
    }
  }

  &__box {
    display: flex;
    align-items: center;
    justify-content: center;
    border: getVwLaptop(1px) solid #ff33b5;
    border: getVwLaptop(4.5px) solid #ff33b5;
    border-radius: getVwLaptop(78px);
    padding: getVwLaptop(125px) getVwLaptop(105.29px) getVwLaptop(114.58px) getVwLaptop(107px);
    overflow: hidden;
    background: #0d0d0d;
    margin: 0 auto;
  }

  &__presentation {
    position: relative;
    flex: 1;
  }

  &__vectors {
    width: getVw(799px, $diagram-minimizer);
    height: getVw(475px, $diagram-minimizer);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    &--mobile {
      display: none;
    }
  }

  &__explanation {
    width: 100%;
    max-width: getVwLaptop(431px);
    flex: 0 0 getVwLaptop(431px);
  }

  &__title {
    @include font-din-condensed;
    color: white;
    font-size: getVwLaptop(64px);
    font-weight: 700;
    margin-bottom: getVwLaptop(40.42px);
    text-transform: uppercase;
    letter-spacing: getVwLaptop(-2.5px);
  }

  &__fact {
    margin-bottom: getVwLaptop(68px);
    font-size: getVwLaptop(16px);
    font-weight: 300;
    line-height: getVwLaptop(22.4px);
  }

  &__details {
    font-size: getVwLaptop(16px);
    line-height: getVwLaptop(22.4px);
  }

  &__grid {
    display: flex;
    flex-direction: column;
    gap: getVwLaptop(4.35px);
    position: absolute;
    top: 50%;
    left: getVwLaptop(345px);
    transform: translateY(-50%);
  }

  &__grid-group {
    display: flex;
    gap: getVwLaptop(4.35px);
    margin-left: auto;
  }

  &__grid-item {
  }

  &__label {
    margin-top: getVwLaptop(8px);
  }
}

.zh .business-diagram {
  &__grid {
    left: getVw(402px, $diagram-minimizer);
  }

  &__vectors {
    left: getVw(-20px, $diagram-minimizer);
  }
}

@media screen and (max-width: 899px) {
  .business-diagram {
    .gi {
      font-size: getVwMobile(14px);
      border-radius: getVwMobile(16px);
      border-width: getVwMobile(0.75px);
      height: getVwMobile(52px);
      padding: 0 getVwMobile(14px);
      background: rgba(13, 13, 13, 1);
      border-color: transparent;
      color: white;

      &--mobile {
        &-accent {
          border-color: rgba(255, 51, 181, 1);
        }
      }
    }

    &__box {
      flex-direction: column;
      padding: getVwMobile(46px) getVwMobile(16px) getVwMobile(23px) getVwMobile(16px);
      border: none;
      background: rgba(9, 9, 9, 1);
    }

    &__vectors {
      display: none;

      &--mobile {
        display: block;
        width: getVwMobile(200px);
        position: static;
        top: unset;
        left: unset;
        transform: none;
        margin-left: getVwMobile(20px);
        margin-top: getVwMobile(14px);
        margin-bottom: getVwMobile(4px);
      }
    }

    &__fact {
      font-size: getVwMobile(12px);
      line-height: getVwMobile(12px);
      @include text-gradient(rgba(255, 51, 181, 1), #8f1d65);
      margin-bottom: getVwMobile(14px);
    }

    &__title {
      font-size: getVwMobile(39px);
      margin-bottom: getVwMobile(0px);
      letter-spacing: getVwMobile(-1.5px);
    }

    &__details {
      font-size: getVwMobile(9px);
      line-height: getVwMobile(12px);
      @include text-gradient(#666666, white);
      max-width: getVwMobile(218px);
    }

    &__explanation {
      width: 100%;
      max-width: 100%;
      flex: 1;
    }

    &__presentation {
      width: 100%;
      min-height: getVwMobile(230px);
      display: flex;
      flex-direction: column;
    }

    &__grid {
      position: static;
      top: unset;
      left: unset;
      transform: none;
      gap: getVwMobile(4.35px);
    }

    &__grid-group {
      gap: getVwMobile(4.35px);
    }

    &__note {
      font-size: getVwMobile(9px);
      line-height: getVwMobile(12px);
      padding: getVwMobile(14px) getVwMobile(18px);
      border-radius: getVwMobile(16.2px);
      border: getVwMobile(1px) solid rgba(28, 28, 28, 1);
      margin-top: getVwMobile(14px);
    }
  }
}
