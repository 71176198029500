@import 'shared/vw';
@import 'shared/mixins';

.round-select {
  .mat-mdc-select-arrow {
    position: relative !important;
    width: 16px !important;
    height: 16px !important;

    &::after {
      content: '' !important;
      position: absolute !important;
      background-image: url('../../assets/icon/analytics/arrow-down.svg') !important;
      background-size: cover !important;
      width: 16px !important;
      height: 16px !important;
      display: block !important;
    }
    svg {
      display: none !important;
    }
  }
  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }

  .mdc-notched-outline {
    display: none !important;
  }

  .mat-mdc-text-field-wrapper {
    background: #272727 !important;
    border-radius: getVwDesktop(100px) !important;
    min-width: getVwLaptop(180px) !important;
    max-width: getVwLaptop(300px) !important;
    padding-left: getVwDesktop(24px) !important;
  }

  .mat-mdc-select-placeholder {
    font-size: getVwDesktop(18px) !important;
  }

  .mat-mdc-select-value-text {
    font-size: getVwDesktop(16px) !important;
    text-transform: capitalize !important;
  }

  .mat-mdc-form-field-infix {
    padding-top: getVwDesktop(12px) !important;
    padding-bottom: getVwDesktop(12px) !important;
    min-height: unset !important;
    width: getVwDesktop(140px) !important;
  }

  .mdc-list-item__primary-text {
    text-transform: capitalize !important;
  }

  .mat-mdc-option .mat-pseudo-checkbox-minimal {
    margin-left: 6px !important;
  }

  &.mat-mdc-select-panel {
    margin-top: getVwDesktop(12px) !important;
    background-color: #272727 !important;
    border-radius: getVwDesktop(24px) !important;
    padding: getVwDesktop(22px) getVwDesktop(20px) !important;
    @include verticalBeautifyScroll;

    &::-webkit-scrollbar-track {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    .mat-mdc-option {
      &:not(:last-child) {
        border-bottom: getVwDesktop(1px) solid #3f3f3f !important;
      }
    }

    .mdc-list-item__primary-text {
      color: white !important;
    }

    .mdc-list-item--selected .mdc-list-item__primary-text {
      font-weight: 500 !important;
    }
  }

  .mat-mdc-select-placeholder,
  .mat-mdc-select-value .mat-mdc-select-value-text {
    font-weight: 500 !important;
  }

  &--dark {
    .mat-mdc-select-placeholder,
    .mat-mdc-select-value-text,
    .mat-mdc-select-arrow {
      color: white !important;
    }

    .mat-mdc-text-field-wrapper {
      background: #161616 !important;
    }
  }

  &--light {
    .mat-mdc-option.mdc-list-item {
      &:not(:last-child) {
        border-bottom: 1px solid #e8e8e8;
      }
    }
    .mat-pseudo-checkbox::after {
      border: none !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: contain !important;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABfklEQVR4Xq2Vv06EQBDGeQT6A26hsDDRwkajic0VdvbGwgtsYXfUNtr4HPogJpSWBq6w5BF4hHW/5UCY5c+yOskv5Jidb3Zmlj3HmTBWpq63j5+9IslWRVzKpwB+kVR4F+Rxyr4fGY2bNQRBoBGcQyZ8M04U7JPdYYea0AzVSlZE9Xp2aAcNXIbUoLrKsHNtsSVaJeifZVvGqHozwZAGFv2VrN39gNMY//NBhLdnYv16o/lYuXWdIOcpdZiixDenIooiwfiV5vdy/uJ4C857l654JJ/4Tdd4aJPNcA3F8QGWqEBzrHfXIrw4Fv7HveYzFW8YTvC0UQI0yVJxlWAly6AvAeOXvSQ24pJqcsjdJOHJ0VJxCc8cHCXd8UvTLnUU784XiOPK4KnDvrYudfwXUru+LryJNtmC66e9i5DJ5nsYA1rt7hvD3x9daIvq/ZDNDdwI3D9Thkps2oWY0Z1TUzPJk3cqMg7PtJ6bGILq2fCsW1X99fMM7cAxp3Fd+wFfyCNZXbzQYQAAAABJRU5ErkJggg==') !important;
      width: 12px !important;
      height: 12px !important;
      transform: unset !important;

      @include min-width-lg() {
        width: 16px !important;
        height: 16px !important;
      }
    }
    .mat-mdc-option:hover,
    .mat-mdc-option.mat-mdc-option-active,
    &.mat-mdc-select-panel {
      background-color: #f3f3f3 !important;
    }
    .mat-mdc-option.mat-mdc-option-active .mdc-list-item__primary-text {
      font-weight: 500 !important;
    }
    .mat-mdc-text-field-wrapper {
      background: #f3f3f3 !important;
    }
    .mat-mdc-select-placeholder,
    .mat-mdc-select-value-text,
    .mat-mdc-select-arrow {
      color: black !important;
    }
    .mdc-list-item__primary-text {
      color: black !important;
    }
    .mat-mdc-select-arrow {
      &::after {
        background-image: url('../../assets/icon/analytics/arrow-down-black.svg') !important;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &.mat-mdc-select-panel {
      border-radius: 16px !important;
    }
    .mat-mdc-option {
      padding: 0 2px 0 8px !important;
      min-height: 36px !important;
    }
    .mat-mdc-select-placeholder,
    .mat-mdc-select-value-text {
      font-size: 13px !important;
    }
    .mat-mdc-text-field-wrapper {
      min-width: getVwMobile(98px) !important;
      min-height: getVwMobile(35px) !important;
      display: flex !important;
      align-items: center !important;
      max-width: unset !important;
      padding: 0 getVwMobile(16px) !important;
    }
    .mat-mdc-select-panel .mat-mdc-option {
      padding: 4px 8px !important;
    }
    .mdc-list-item__primary-text {
      font-size: 13px !important;
    }
  }
}

.round-select__flag-text {
  margin-left: 32px;
}

.round-select__wrapper {
  display: flex;
  align-items: center;

  .round-select__flag {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.round-select__flag {
  position: absolute;
  content: '';
  display: block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  overflow: hidden;
  width: getVwMobile(18px);
  height: getVwMobile(16px);

  @include min-width-lg() {
    width: getVwDesktop(23px);
    height: getVwDesktop(20px);
  }

  &--by {
    background-image: url('/assets/img/flags/by.png');
  }

  &--am {
    background-image: url('/assets/img/flags/am.png');
  }

  &--kg {
    background-image: url('/assets/img/flags/kg.png');
  }

  &--kz {
    background-image: url('/assets/img/flags/kz.png');
  }

  &--ru {
    background-image: url('/assets/img/flags/ru.png');
  }

  &--ua {
    background-image: url('/assets/img/flags/ua.png');
  }

  &--uz {
    background-image: url('/assets/img/flags/uz.png');
  }

  @include min-width-lg() {
    top: unset !important;
    left: unset !important;
    transform: unset !important;
    display: inline !important;
  }
}

.cdk-overlay-pane .round-select__minipanel {
  width: getVwMobile(220px) !important;
  max-width: unset !important;
  min-width: 180px !important;
  transform: translateX(-80%) !important;
  overflow-x: visible !important;
  border-radius: getVwMobile(14px) !important;

  .geography__option {
    @include min-width-lg() {
      padding-left: getVwDesktop(20px) !important;
    }
  }

  @include min-width-lg() {
    transform: unset !important;
    overflow-x: hidden !important;
    min-width: unset !important;
    border-radius: getVwDesktop(12px) !important;
  }
}
