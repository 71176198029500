@import '../shared/mixins';

.mdc-snackbar {
  .mdc-snackbar__surface {
    background-color: rgb(15, 15, 15) !important;

    .mdc-snackbar__label {
      @include font-raleway;
      font-weight: 400 !important;
      font-size: 12px !important;
      color: white !important;
      padding-top: 8px !important;
      padding-bottom: 8px !important;
    }
  }
}
