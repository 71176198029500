.os-theme-dark {
  --os-scrollbar-width: 8px;
  --os-scrollbar-thumb-border-radius: 10px;
  --os-handle-bg: #e0018e;
  --os-handle-bg-hover: #e0018e;
  --os-handle-bg-active: white;
  --os-size: 9px;
  --os-track-bg: #2a2a2a;
  --os-track-bg-hover: #2a2a2a;
  --os-track-bg-active: #2a2a2a;
}
