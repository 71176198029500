// -----------------------------RALEWAY-----------------------------
@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-Light.woff2') format('woff2'),
    url('../assets/fonts/Raleway/Raleway-Light.woff') format('woff'),
    url('../assets/fonts/Raleway/Raleway-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-Regular.woff2') format('woff2'),
    url('../assets/fonts/Raleway/Raleway-Regular.woff') format('woff'),
    url('../assets/fonts/Raleway/Raleway-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-Medium.woff2') format('woff2'),
    url('../assets/fonts/Raleway/Raleway-Medium.woff') format('woff'),
    url('../assets/fonts/Raleway/Raleway-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/Raleway/Raleway-SemiBold.woff') format('woff'),
    url('../assets/fonts/Raleway/Raleway-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-Bold.woff2') format('woff2'),
    url('../assets/fonts/Raleway/Raleway-Bold.woff') format('woff'),
    url('../assets/fonts/Raleway/Raleway-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// -----------------------------BEBAS NEUE-----------------------------
@font-face {
  font-family: 'BebasNeue';
  src: url('../assets/fonts/Bebas Neue/BebasNeue-Bold.woff2') format('woff2'),
    url('../assets/fonts/Bebas Neue/BebasNeue-Bold.woff') format('woff'),
    url('../assets/fonts/Bebas Neue/BebasNeue-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// -----------------------------LEAGUE GOTHIC-----------------------------
@font-face {
  font-family: 'LeagueGothic';
  src: url('../assets/fonts/LeagueGothic/LeagueGothic-Regular.woff2') format('woff2'),
    url('../assets/fonts/LeagueGothic/LeagueGothic-Regular.woff') format('woff'),
    url('../assets/fonts/LeagueGothic/LeagueGothic-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
// -----------------------------MONTSERRAT-----------------------------
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Light.woff2') format('woff2'),
    url('../assets/fonts/Montserrat/Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
    url('../assets/fonts/Montserrat/Montserrat-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Bold.woff2') format('woff2'),
    url('../assets/fonts/Montserrat/Montserrat-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
// -----------------------------RF DEWI-----------------------------
@font-face {
  font-family: 'RF Dewi';
  src: url('../assets/fonts/RFDewi/RFDewiExpanded-Light.woff2') format('woff2'),
    url('../assets/fonts/RFDewi/RFDewiExpanded-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'RF Dewi';
  src: url('../assets/fonts/RFDewi/RFDewiExpanded-Regular.woff2') format('woff2'),
    url('../assets/fonts/RFDewi/RFDewiExpanded-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'RF Dewi';
  src: url('../assets/fonts/RFDewi/RFDewiExpanded-Bold.woff2') format('woff2'),
    url('../assets/fonts/RFDewi/RFDewiExpanded-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
// -----------------------------TT FIRS NEUE-----------------------------
@font-face {
  font-family: 'TT Firs Neue';
  src: url('../assets/fonts/TTFirsNeue/TTFirsNeue-Thin.woff2') format('woff2'),
    url('../assets/fonts/TTFirsNeue/TTFirsNeue-Thin.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../assets/fonts/TTFirsNeue/TTFirsNeue-Light.woff2') format('woff2'),
    url('../assets/fonts/TTFirsNeue/TTFirsNeue-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../assets/fonts/TTFirsNeue/TTFirsNeue-Regular.woff2') format('woff2'),
    url('../assets/fonts/TTFirsNeue/TTFirsNeue-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../assets/fonts/TTFirsNeue/TTFirsNeue-Medium.woff2') format('woff2'),
    url('../assets/fonts/TTFirsNeue/TTFirsNeue-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../assets/fonts/TTFirsNeue/TTFirsNeue-Bold.woff') format('woff2'),
    url('../assets/fonts/TTFirsNeue/TTFirsNeue-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

// -----------------------------DIN Condensed-----------------------------
@font-face {
  font-family: 'DIN Condensed';
  src: url('../assets/fonts/DIN Condensed/DIN.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Condensed';
  src: url('../assets/fonts/DIN Condensed/DIN Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Condensed';
  src: url('../assets/fonts/DIN Condensed/dincondensedc.woff2') format('woff2'),
    url('../assets/fonts/DIN Condensed/dincondensedc.woff') format('woff'),
    url('../assets/fonts/DIN Condensed/dincondensedc.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// -----------------------------GEOMETRIA-----------------------------
@font-face {
  font-family: 'Geometria';
  src: url('../assets/fonts/Geometria/Geometria-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geometria';
  src: url('../assets/fonts/Geometria/Geometria.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geometria';
  src: url('../assets/fonts/Geometria/Geometria-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
