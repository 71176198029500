@import 'shared/mixins';
@import 'shared/colors';

/* Стили заголовков */

// h1 зарезервировано для супер большого заголовка

.h2 {
  @include font-bebas-neue;
  font-style: normal;
  font-weight: 400;
  font-size: 3.75rem;
  line-height: 1.2;
  color: $black;

  @media (max-width: $sm) {
    font-size: 2.2rem;
  }

  &._white {
    color: $white;
  }
}

.h3 {
  @include font-bebas-neue;
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.2;
  color: $black;
}

// h4 резерв на будущее

.h5 {
  @include font-raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.2;
  color: $black;
}

/* Стили основного текста */

.body1 {
  @include font-raleway;
  font-style: normal;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 1.4;
  color: $black;
}

.body2 {
  @include font-raleway;
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.2;
  color: $black;
}

.body3 {
  @include font-raleway;
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.2;
  color: $gray400;
}

.pink {
  color: $brand-color;
}

.underline {
  text-decoration: underline;
}
/* Стили подписей, лейблов */

/* Стили кликабельных элементов */
