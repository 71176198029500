@import 'shared/vw';
@import 'shared/mixins';

.chart-tabs {
  .mdc-tab__text-label {
    font-family: 'TT Firs Neue', sans-serif !important;
    font-weight: 400 !important;
    font-size: getVwDesktop(20px) !important;
    letter-spacing: getVwDesktop(-0.5px) !important;
  }

  .mdc-tab-indicator {
    .mdc-tab-indicator__content {
      border-color: #3f3f3f !important;
      opacity: 1 !important;

      &--underline {
        @include adaptive-prop-size(border-top-width, 1px, 2px);
      }
    }
  }

  .mdc-tab__text-label {
    color: #686868 !important;
  }

  .mdc-tab--active {
    .mdc-tab-indicator__content {
      border-color: #e0008d !important;
    }
    .mdc-tab__text-label {
      color: white !important;
    }
  }

  @media screen and (max-width: 1024px) {
    .mdc-tab__text-label {
      font-size: getVwMobile(12px) !important;
    }
  }
}

.tab-group {
  &--no-header {
    .mat-mdc-tab-header {
      display: none !important;
    }
  }
}
