@import 'swiper/scss';
@import 'swiper/scss/autoplay';
@import 'swiper/scss/pagination';
@import '../shared/mixins';
@import '../shared/animations';
@import '../shared/vw';

@mixin sliderTheme($color-bg, $color-accent, $border-color, $color-action) {
  .swiper-pagination.swiper-pagination-bullets {
    background: $color-bg !important;

    .swiper-pagination-bullet {
      background: $color-action !important;

      &-active {
        background: $color-accent !important;

        &::after {
          border-color: $border-color !important;
        }
      }
    }
  }
}

$slider-accent-1: rgba(white, 0.6);
$slider-accent-2: white;
$slider-action-1: rgba(white, 0.4);
$slider-action-2: white;
$slider-bg-1: rgb(62, 54, 51, 0.45);
$slider-bg-2: transparent;
$slide-height: 680px;
$slide-height: 720px;
$slide-height: 730px;
$slide-movie-height: getVwLaptop(840px);

.slider {
  height: getVwLaptop($slide-height);
  position: relative;
  width: 100%;

  &__media-action {
    cursor: pointer;
    position: absolute;
    z-index: 2;

    &--play {
      right: getVwLaptop(660px);
      top: getVwLaptop(100px);

      .slider__media-icon {
        width: getVwLaptop(188px);
        height: getVwLaptop(188px);
      }
    }

    &::after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: calc(100% + #{getVwLaptop(5px)});
      height: calc(100% + #{getVwLaptop(5px)});
      animation: main-pulsate $main-pulse-timing infinite linear;
      animation-fill-mode: both;
      border-radius: 50%;
    }
  }

  &__actions {
    position: absolute;
    right: getVwLaptop(260px);
    bottom: getVwLaptop(85px);
    z-index: 2;
    min-width: getVwLaptop(150px);
  }

  &__action {
    @include font-din-condensed;
    font-weight: 700;
    font-size: getVwLaptop(18px);
    padding: getVwLaptop(18px) getVwLaptop(26px);
    border: getVwLaptop(3px) solid white;
    border-radius: getVwLaptop(19px);
    background: transparent;
    text-transform: uppercase;
    color: white;
  }

  .swiper-slide {
    width: 100%;
  }

  .swiper-pagination.swiper-pagination-bullets {
    bottom: getVwLaptop(84.16px);
    padding: getVwLaptop(16px) getVwLaptop(22px);
    border-radius: getVwLaptop(45px);
    background: transparent;
    width: auto;
    left: getVwLaptop(85.34px);
    display: flex;
    align-items: center;
    z-index: 20;
    gap: 0 !important;

    .swiper-pagination-bullet {
      position: relative;
      width: getVwLaptop(6px);
      height: getVwLaptop(6px);
      opacity: 1;

      &.swiper-pagination-bullet-active {
        background-color: white !important;
        border: none !important;
      }

      &:not(:last-child) {
        margin: 0 getVwLaptop(26px) 0 0;
      }

      &::after {
        position: absolute;
        content: '';
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: getVwLaptop(28px);
        height: getVwLaptop(28px);
        border-radius: getVwLaptop(8.54px);
        border: getVwLaptop(0.95px) solid transparent;
        border: getVwLaptop(1.25px) solid transparent;
      }
    }
  }

  &__element {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-height: getVwLaptop($slide-height);
  }

  &__media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    pointer-events: none;
  }

  &__navigation {
    display: block;
    width: 100%;
    height: 100%;
  }

  &--gradient {
    &-black {
    }
  }

  &--variant {
    &-1 {
      @include sliderTheme($slider-bg-1, $slider-accent-1, transparent, $slider-action-1);
    }

    &-2 {
      @include sliderTheme($slider-bg-2, $slider-accent-2, $slider-accent-2, $slider-action-2);
    }
  }

  &--big-one {
    height: getVwLaptop(840px);
  }
}

.swiper-wrapper {
  position: relative;
}

.movie {
  .swiper,
  .swiper-slide,
  .swiper-wrapper,
  .slider,
  .slider__element {
    overflow: visible !important;
  }

  .swiper-slide:not(.swiper-slide-active) video {
    display: none !important;
  }

  .news,
  .events {
    .swiper {
      &::before,
      &::after {
        display: none !important;
      }

      .swiper-pagination {
        display: none !important;
      }
    }
  }
}

@media screen and (max-width: 899px) {
  .slider {
    height: getVwMobile(320px);

    .swiper-pagination.swiper-pagination-bullets {
      bottom: getVwTablet(130px);
      padding: getVwMobile(7.22px) getVwTablet(22px);
      border-radius: getVwTablet(45px);
      left: 0;
      right: 0;
      justify-content: center;

      .swiper-pagination-bullet {
        width: getVwMobile(4px);
        height: getVwMobile(4px);

        &:not(:last-child) {
          margin: 0 getVwMobile(13px) 0 0;
        }

        &::after {
          width: getVwMobile(18px);
          height: getVwMobile(18px);
          border-radius: getVwMobile(6px);
          border: getVwMobile(1.25px) solid transparent;
        }
      }
    }

    &__media {
      &--video {
        object-fit: none !important;
        height: 120% !important;
        transform: translateY(-50%) !important;
        top: 50% !important;
      }
    }

    &__element {
      min-height: getVwTablet($slide-height);
    }

    &__actions {
      right: 0;
      left: 0;
      margin: auto;
      bottom: getVwMobile(120px);
      min-width: getVwTablet(150px);
      text-align: center;
    }

    &__action {
      font-size: getVwMobile(14px);
      padding: getVwMobile(12px) getVwMobile(16px) getVwMobile(10px) getVwMobile(16px);
      border-radius: getVwMobile(12px);
      background: #000000;
      border: unset;
      color: #ffffff;
    }

    &__media-action {
      &--play {
        right: getVwTablet(240px);
        top: getVwTablet(100px);

        .slider__media-icon {
          width: getVwTablet(188px);
          height: getVwTablet(188px);
        }
      }

      &::after {
        width: calc(100% + #{getVwTablet(5px)});
        height: calc(100% + #{getVwTablet(5px)});
      }
    }

    &--big-one-mobile,
    &--big-one {
      height: getVwMobile(420px);
    }
  }

  .movie .slider {
    &__media {
      &--video {
        height: 100% !important;
        object-fit: contain !important;
      }
    }
  }
}
