@import 'shared/mixins';

#customBalloonContainer {
  width: fit-content;
  max-width: 400px; /* Limit width to prevent overflow */
  word-wrap: break-word;
  white-space: normal;
  background: white;
  border-radius: 12px;
  @include adaptive-prop-size(padding, 6px, 12px);
}
