@import '../shared/colors';

.color {
  &--main {
    color: $brand-color;
  }

  &--main-light {
    color: #ff33b5;
  }
}
