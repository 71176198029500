.collapse {
  transition: height 600ms ease, padding 600ms ease, margin 600ms ease, border 600ms ease;

  &--active {
    padding: 0 !important;
    height: 0 !important;
    margin: 0 !important;
    border-width: 0 !important;
    overflow: hidden !important;
  }
}
