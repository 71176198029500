@import 'shared/vw';
@import 'shared/mixins';

.ios-tabs {
  .mdc-tab__text-label {
    font-family: 'TT Firs Neue', Arial, sans-serif !important;
    font-weight: 400 !important;
    @include adaptive-prop-size(font-size, 12px, 20px);
    letter-spacing: 0px !important;
  }

  .mat-mdc-tab-labels {
    background: #f2f2f2 !important;
    @include adaptive-prop-size(border-radius, 100px, 100px);
  }

  .mat-mdc-tab.mdc-tab {
    //@include adaptive-prop-size(padding, 13px 18px, 20px 44px);
    @include adaptive-prop-size(border-radius, 100px, 100px);
    @include adaptive-prop-size(height, 36px, 56px);

    .mdc-tab__text-label {
      color: black !important;
    }

    &--active {
      background: black !important;

      .mdc-tab__text-label {
        color: white !important;
      }
    }
  }

  .mat-ripple,
  .mdc-tab__ripple,
  .mdc-tab-indicator__content {
    display: none !important;
  }
}
