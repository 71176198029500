$brand-color: #e0008d;
$brand-red: #db004f;
$brand-gray: #3f3f3f;
// TODO: систематизировать цвета
$bg-gray: #f3f3f3;
$gray200: #eeeeee;
$gray300: #b3b3b3;
$gray400: #7a7a7a;
$gray-archive: #4d4d4d;
$gray: #272727;
$gray800: #161616;

$error600: #e53935;

$white: #ffffff;
$white-op50: #ffffff7f;
$white-gray: #e8e8e8;
$black-7: rgba(0, 0, 0, 0.7);
$black-5: rgba(0, 0, 0, 0.5);
$black: #000000;
$money-black: #0b0b0b;
$milk-gray: #686868;

$success: #00bfa5;
$bright-green: #00a842;
$orange: #ff8b20;

$nero: #242424;

$separating-gray: #f1f1f2;
